import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';

export default class Social extends React.Component {
    render() {
        return (
            <section>
                <h2>{get(this.props, 'pageContext.site.siteMetadata.social.title')}</h2>
                <ul className="icons">
                    {map(get(this.props, 'pageContext.site.siteMetadata.social.follow_section'), (link, link_idx) => (
                        <li key={link_idx}><a href={get(link, 'link_url')} className={'icon style2 ' + get(link, 'icon')}><span className="screenreader-only">{get(link, 'title')}</span></a></li>
                    ))}
                </ul>
            </section>
        );
    }
}
