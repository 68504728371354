import React from 'react';
import get from 'lodash/get';

export default class Contact extends React.Component {
    render() {
        return (
            <section>
                <h2>{get(this.props, 'pageContext.site.siteMetadata.contact.title')}</h2>
                <form 
                    name="contact-form"
                    method="post" 
                    data-netlify="true" 
                    data-netlify-honeypot="bot-field"
                >
                    <input type="hidden" name="form-name" value="contact-form" />
                    <div className="fields">
                        <div className="field half">
                            <label htmlFor="name" className="screenreader-only">Name</label>
                            <input type="text" name="name" id="name" placeholder="Name" />
                        </div>
                        <div className="field half">
                            <label htmlFor="email" className="screenreader-only">Email</label>
                            <input type="email" name="email" id="email" placeholder="Email" />
                        </div>
                        <div className="field">
                            <label htmlFor="message" className="screenreader-only">Message</label>
                            <textarea name="message" id="message" placeholder="Message" />
                        </div>
                    </div>
                    <button className="primary">Send</button>
                </form>
            </section>
        );
    }
}
