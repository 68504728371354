import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';

import safePrefix from '../utils/safePrefix';

export default class Menu extends React.Component {
    render() {
        return (
            <nav id="menu">
                <h2>Menu</h2>
                <ul>
                    {map(get(this.props, 'pageContext.site.siteMetadata.menus.main'), (item, item_idx) => (
                        <li key={item_idx}><a href={safePrefix(get(item, 'url'))}>{get(item, 'title')}</a></li>
                    ))}
                </ul>
            </nav>
        );
    }
}
